import { useState } from 'react';
import {
    Button,
    Confirm,
    useRecordContext,
    useDelete,
} from 'react-admin';

export const DeleteOrderButton = () => {
    const record = useRecordContext();
    const [open, setOpen] = useState(false);

    const [remove, { isLoading }] = useDelete(
        'orders',
        { id: record && record.id }
    );

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = () => {
        remove();
        setOpen(false);
    };
    // @ts-ignore
    if (record.status === 'COMPLETED') return (<></>)

    return (
        <>
            <Button label="Transaktion abbrechen" onClick={handleClick} />
            <Confirm
                isOpen={open}
                loading={isLoading}
                title={`Wenn sie die Transaktion abbrechen, wir der Status zurückgesetzt`}
                content="Sind sie sicher, dass sie das Item verfügbar machen wollen?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    );
};