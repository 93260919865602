import {
    Edit,
    ImageField, ImageInput,
    ReferenceArrayInput,
    SimpleForm,
    SimpleFormIterator,
    TextInput, useEditContext,
    useRecordContext, useRedirect, useReference
} from 'react-admin';
import React from "react";

const ItemEditGuard = () => {
    const { record } = useEditContext();
    const redirect = useRedirect();
    if (record.type === "ThingkItem") redirect(`/items/${record.id}/show`);
    return <></>
}

export const ItemEdit = () => (
    <Edit mutationMode="optimistic">
        <ItemEditGuard />
        <SimpleForm>
            <TextInput source="title" fullWidth />
            <TextInput source="description" multiline={true} fullWidth/>

            <ReferenceArrayInput reference={"categories"} source={"categories"}></ReferenceArrayInput>
            <ImageInput source="mediaItems" label="Bilder" multiple>
                <ImageField source="src" title={"title"} />
            </ImageInput>
        </SimpleForm>
    </Edit>
);