import {
    ArrayField,
    Datagrid,
    DateField,
    ImageField,
    ReferenceField,
    SelectField,
    Show,
    SimpleShowLayout,
    SingleFieldList,
    TextField
} from 'react-admin';
import React from "react";
import {orderStatus} from "./order-status-enums";
import {DeleteOrderButton} from "../components/delete_order_button";


export const OrderShow = () =>
    (
        <Show>
            <SimpleShowLayout>
                <TextField source="id" label={"Order ID"}/>
                <DateField source="createdDate"/>
                <ReferenceField source="item" reference="items" resource={"items"} link={"show"} label={"Bilder"}>
                    <ArrayField source={"mediaItems"} perPage={1}>
                        <SingleFieldList><ImageField source="src"/></SingleFieldList>
                    </ArrayField></ReferenceField>
                <ReferenceField source="item" reference="items" resource={"items"} link={"show"}>
                    <TextField source={"title"}></TextField></ReferenceField>
                <TextField source="borrower.displayName" label={"Geliehen von"}/>
                <SelectField source="status" choices={orderStatus} label={"Status"}/>
                <DateField source="expirationDate" label={"Reservierung läuft ab"} showTime={true}/>
                <SelectField source="transferStatus" choices={orderStatus} label={"Transferstatus"}/>
                <SelectField source="orderStatus" choices={orderStatus} label={"Interner Status"} va/>
                <ArrayField source={"history"}>
                    <Datagrid unselectable={"on"} bulkActionButtons={false}>
                        <DateField source="createdDate" showTime label={"Änderungszeitpunkt"}/>
                        {/*          <TextField source={"createdBy.displayName"} label={"Änderung durch"}></TextField>*/}
                        <SelectField source="status" choices={orderStatus} label={"Neuer Status"}/>
                    </Datagrid>
                </ArrayField>
            </SimpleShowLayout>
            <SimpleShowLayout>
                <DeleteOrderButton />
            </SimpleShowLayout>
        </Show>
    );