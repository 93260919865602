import {
    ArrayField,
    Datagrid,
    DateField,
    ImageField,
    List,
    ReferenceField, SelectField,
    SingleFieldList,
    TextField
} from 'react-admin';
import React from "react";
import {orderStatus} from "./order-status-enums";

export const OrderList = () => (
    <List sort={{ field: 'createdDate', order: 'DESC' }}>
        <Datagrid rowClick="show">
            <TextField source="id" label={"OrderId"}/>
            <DateField source="createdDate" label={"Gestartet"}/>
            <ReferenceField source="item" reference="items" resource={"items"} link={false} label={""}>
                <ArrayField source={"mediaItems"} perPage={1} filter={{ type: 'image' }}>
                    <SingleFieldList resource={"mediaItems"} linkType={false}><ImageField source="src" sx={{ '& img': { maxWidth: 40, maxHeight: 40, objectFit: 'contain' } }}/></SingleFieldList>
                </ArrayField></ReferenceField>
            <ReferenceField source="item" reference="items" resource={"items"} link={"show"} label={"Item"}>
                <TextField source={"title"}></TextField></ReferenceField>
           {/* <TextField source="borrower.displayName" label={"Geliehen von"}/>*/}

            <SelectField source="transferStatus" choices={orderStatus} label={"Transferstatus"} sortable={false} />
            <SelectField source="status" choices={orderStatus} label={"Transaktionsstatus"} sortable={false} />
            <DateField source="expirationDate" label={"Läuft ab"} showTime={true}/>
        </Datagrid>
    </List>
);