import {
    ArrayField, BooleanField,
    ChipField,
    Datagrid, DateField, EditButton, FieldProps,
    ImageField,
    List, Pagination,
    ReferenceArrayField, SelectField,
    SingleFieldList,
    TextField,
    TextInput, TopToolbar, useListContext,
    useRecordContext, useShowContext
} from 'react-admin';
import React from "react";
import {orderStatus} from "../orders/order-status-enums";

const itemFilters = [
    <TextInput label="Suche" source="q" alwaysOn/>];

export const ItemList = (props: any) => {
    const {record, isLoading} = useShowContext();
    console.log(record)
    return (
    <List filters={itemFilters} perPage={25}>

        <Datagrid rowClick="show">
            <TextField source={"owner.displayName"} label={"Gehört"}/>
            <TextField source={"inventoryNo"} label={"ThingkID"}/>
            <ArrayField source={"mediaItems"} perPage={1} filter={{ type: 'image' }}>
                <SingleFieldList linkType={false}><ImageField source="src" sx={{ '& img': { maxWidth: 40, maxHeight: 40, objectFit: 'contain' } }} /></SingleFieldList>
            </ArrayField>
            <TextField source="title"/>
            <SelectField source="deltaStatus.status" choices={orderStatus} label={"Status"}/>
            <SelectField source="deltaStatus.transferStatus" choices={orderStatus} label={"Transfer Status"}/>
            <TextField source={"thingkStatus"} label={"Thingk Status"}/>
            {/*<TextField source={"blockedReason"} label={"Thingk block reason"}/>*/}

           {/* <ReferenceArrayField source="categories" reference="categories">
                <SingleFieldList>
                    <ChipField source="name"/>
                </SingleFieldList>
            </ReferenceArrayField>*/}
          {/*  <TextField source={"location.zip"} label={"PLZ"}/>*/}

            <BooleanField source={"isAvailable"} label={"Verfügbar"}></BooleanField>
            <ArrayField source={"orderHistory"} label={"Reserviert bis"} perPage={1}>
                <SingleFieldList linkType={false}>
                    <DateField source="expirationDate" label={"reserviert bis"} showTime={true}/>
                </SingleFieldList>
            </ArrayField>
        </Datagrid>
    </List>
);}